import styled, { css } from "styled-components";
import Spacing from "../style/tokens/Spacing";
import Borders from "../style/tokens/Borders";
import Colors from "../style/tokens/Colors";
import Typography from "../style/tokens/Typography";
import Button from "../ui/Button/Button";

const hideButtonWidth = "30rem";
const teamsButtonCSS = css`
  width: auto;
  position: absolute;
  top: 0.45rem;
  margin: 0;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  color: ${Colors.text.primary};
`;

export const StyledTeamsList = styled.ul`
  margin: 0;
  margin-bottom: ${Spacing.medium};
  padding: ${Spacing.tight};
  /* max-width: 38rem; */
  box-sizing: border-box;
`;

export const StyledTeamsRemoveMember = styled(Button)`
  position: absolute;
  top: 50%;
  right: 0.5rem;
  margin: 0;
  font-size: 0.65rem;
  transform: translateY(-50%);

  color: ${Colors.text.primary};
  background-color: transparent;

  @media (min-width: ${hideButtonWidth}) {
    display: none;
    &:hover {
      color: ${Colors.text.white};
      background-color: ${Colors.bold.red};
    }
  }
`;

export const StyledTeamsMember = styled.li`
  position: relative;
  max-width: 100%;
  margin: 1rem 0rem;
  padding-right: 6rem;
  &:hover ${StyledTeamsRemoveMember} {
    display: block;
  }
`;

export const StyledTeamsDelete = styled(Button)`
  ${teamsButtonCSS}
  right: 0.45rem;

  @media (min-width: ${hideButtonWidth}) {
    display: none;
    &:hover {
      color: ${Colors.text.white};
      background-color: ${Colors.bold.red};
    }
  }
`;

export const StyledTeamsLeave = styled(Button)`
  ${teamsButtonCSS}
  right: 3.25rem;
  @media (min-width: ${hideButtonWidth}) {
    display: none;
    &:hover {
      color: ${Colors.text.primary};
      background-color: ${Colors.bold.yellow};
    }
  }
`;

export const StyledTeamsSave = styled(Button)`
  ${teamsButtonCSS}

  display: block;
  /* right: 0.45rem; */
  right: 3.25rem;

  @media (min-width: ${hideButtonWidth}) {
    &:hover {
      color: ${Colors.text.white};
      background-color: ${Colors.bold.green};
    }
  }
`;

export const StyledTeamsItem = styled.li`
  position: relative;
  margin: 0;
  margin-bottom: ${Spacing.medium};
  padding: 0;
  list-style: none;

  width: 100%;

  border-radius: ${Borders.radius.standard};

  ${(props) =>
    props.editing
      ? `border: 1px solid ${Colors.bold.yellow};`
      : `border: 1px solid ${Colors.grayscale.light};`}

  box-sizing: border-box;
  transition: all 0.2s;
  overflow: hidden;

  &:hover {
    background-color: #fff;
    box-shadow: 0 0.5rem 0.45rem -0.375rem ${Colors.grayscale.light};

    ${StyledTeamsDelete},
    ${StyledTeamsLeave} {
      display: block;
    }

    &::before {
      content: "";
      position: absolute;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border-radius: ${Borders.radius.standard};
      pointer-events: none;
    }
  }
`;

export const StyledTeamsToggle = styled.details`
  display: block;
  width: 100%;
`;

export const StyledTeamsTitle = styled.summary`
  width: 100%;
  padding: ${Spacing.medium};
  padding-right: 9rem;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &:focus {
    outline: none;
  }
`;

export const StyledTeamsInnerArea = styled.div`
  padding: ${Spacing.loose};
  padding-top: 0;
  @media (min-width: 40rem) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const StyledTeamsInnerAreaColumn = styled.div`
  @media (min-width: 40rem) {
    width: 48%;
  }
`;

export const StyledTeamsDescription = styled.p`
  font-size: ${Typography.body.small};
  margin-top: 0;
  padding: 0 ${Spacing.loose};
  margin-bottom: ${Spacing.medium};
  box-sizing: border-box;
`;

export const StyledTeamsInviteCode = styled.div`
  display: inline-block;
  max-width: 100%;
  position: relative;
  padding: ${Spacing.tight};
  border: ${Borders.full.thin};
  user-select: all;
  box-sizing: border-box;
  line-height: 1.2;
  overflow-x: auto;

  ${(props) =>
    props.copiedInvite &&
    `
      border-color: ${Colors.bold.green};`}
`;

export const StyledTeamsCode = styled.code`
  overflow: auto;
  max-height: 20rem;
  display: block;
  font-size: 0.8rem;
  padding: ${Spacing.medium};
  margin: ${Spacing.medium};
  margin-top: 0;
  border-radius: ${Borders.radius.standard};
  background-color: #eee;
  box-sizing: border-box;
`;

export const StyledTeamsEditCode = styled.textarea`
  width: calc(100% - 2rem);
  max-width: calc(100% - 2rem);
  border: none;
  max-height: 20rem;
  display: block;
  font-size: 0.8rem;
  padding: ${Spacing.medium};
  margin: ${Spacing.medium};
  margin-top: 0;
  border-radius: ${Borders.radius.standard};
  background-color: #eee;
  box-sizing: border-box;

  border-radius: ${Borders.radius.standard};
  border: ${Borders.full.thin};

  :focus {
    outline: none;
  }
`;
