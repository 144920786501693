import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { useAuth } from "../../hooks/useAuth";

import Form from "../ui/Form";
import TextInput from "../ui/TextInput";
import Spacing from "../style/tokens/Spacing";

import {
  StyledLoginPage,
  StyledLoginForm,
  StyledImageContainer,
  StyledImage,
} from "./LoginForm.styled";

function LoginForm() {
  const [formDisabled, setFormDisabled] = useState(false);
  const [renderForm, setRenderForm] = useState(false);
  const [formErrors, setFormErrors] = useState(false);
  const [loginFailure, setLoginFailure] = useState(false);

  let navigate = useNavigate();
  let location = useLocation();
  let authenticate = useAuth();

  // Set the pathname that user is redirected to if authenticated
  let { from } = location.state || { from: { pathname: "/dashboard" } };

  useEffect(() => {
    if (authenticate.user != null) {
      authenticate.user
        ? navigate(from, { replace: true })
        : setRenderForm(true);
    }
  }, [authenticate.user, from]);

  let handleLogin = async (username, password) => {
    try {
      let response = await authenticate.login(username, password);

      if (response && !("error" in response)) {
        if (response) {
          navigate(from, { replace: true });
        }
      } else {
        if (response?.error?.status === 401) {
          setLoginFailure(true);
        }
        setFormDisabled(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  let handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(false);
    setFormDisabled(true);
    setLoginFailure(false);

    let errors = [];

    let username = e.target.username.value;
    let password = e.target.password.value;

    if (username.length === 0) {
      errors.push({ field: "username", message: "Please enter a username" });
    }

    if (password.length === 0) {
      errors.push({
        field: "password",
        message: "Please enter a password",
      });
    }

    // Check if errors are empty
    if (errors.length === 0) {
      handleLogin(username, password);
    } else {
      handleFormErrors(errors);
    }
  };

  let handleFormErrors = (obj) => {
    setFormDisabled(false);
    setFormErrors(obj);
  };

  let handleForgottenPassword = async (e) => {
    navigate("/forgot");
  };

  return (
    renderForm && (
      <StyledLoginPage>
        <StyledImageContainer>
          <StyledImage src="img/browser-snippets-starcard.png" />
        </StyledImageContainer>
        <StyledLoginForm>
          <h1 style={{ fontSize: "3rem", margin: "0 0 1rem" }}>Login</h1>
          <Form
            onSubmit={handleSubmit}
            submitLabel="Login"
            disabled={formDisabled}
            isLoading={formDisabled}
            formErrors={formErrors}
            failure={loginFailure}
          >
            <TextInput
              type="text"
              name="username"
              id="username"
              autoComplete="username"
              label="Username"
              error={
                formErrors && formErrors.find((o) => o.field === "username")
              }
            />
            <TextInput
              type="password"
              name="password"
              id="password"
              autoComplete="current-password"
              label="Password"
              error={
                formErrors && formErrors.find((o) => o.field === "password")
              }
            />
          </Form>
          <p
            style={{
              display: "inline-block",
              cursor: "pointer",
              padding: Spacing.tight,
              marginTop: "-1rem",
            }}
            onClick={handleForgottenPassword}
          >
            Forgot password?
          </p>
          <div
            style={{
              marginBottom: "1rem",
              textAlign: "center",
            }}
          >
            <span style={{ fontFamily: "RedHatDisplay-Bold" }}>
              Beta Edition
            </span>
            <br />
            <br />
            <small>
              Browser Snippets is still being actively developed so things are
              liable to change and maybe even break without notice. Use at your
              own risk.
            </small>
          </div>
        </StyledLoginForm>
      </StyledLoginPage>
    )
  );
}

export default LoginForm;
