import React, { useState, useEffect } from "react";

import { useAuth } from "../../hooks/useAuth";
import userInput from "../../helpers/userInputHelper";

import Form from "../ui/Form";
import TextInput from "../ui/TextInput";

import { StyledPasswordChangeForm } from "./PasswordChangeForm.styled";

import { useNavigate, useLocation } from "react-router-dom";

function PasswordChange() {
  const [formDisabled, setFormDisabled] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);
  const [renderForm, setRenderForm] = useState(false);
  const [formErrors, setFormErrors] = useState(false);

  let navigate = useNavigate();
  let location = useLocation();
  let authenticate = useAuth();

  let { from } = location.state || { from: { pathname: "/" } };

  useEffect(() => {
    if (authenticate.user != null) {
      authenticate.user
        ? navigate(from, { replace: true })
        : setRenderForm(true);
    }
  }, [authenticate.user, from]);

  let handlePasswordChange = async (password, resetToken) => {
    let errors = [];

    try {
      let response = await authenticate.confirmPasswordReset(
        password,
        resetToken
      );

      if (response && !response.success) {
        errors.push({
          field: "password",
          message: response.error.message,
        });
      }

      // Check if errors are empty
      if (errors.length === 0 && response.success) {
        setFormSuccess(true);
        setFormDisabled(true);

        // Redirect user to login after success
        setTimeout(() => {
          navigate(from, { replace: true });
        }, 1000);
      } else {
        handleFormErrors(errors);
      }
    } catch (err) {
      setFormDisabled(false);
      console.log(err);
    }
  };

  let handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(false);
    setFormDisabled(true);

    let errors = [];

    let password = e.target.password.value;
    let passwordConf = e.target.passwordConfirmation.value;

    if (!userInput.isValidPassword(password)) {
      errors.push({
        field: "password",
        message: "Passwords must be at least 8+ characters",
      });
    }

    if (password !== passwordConf) {
      errors.push({
        field: "passwordConfirmation",
        message: "Passwords don't match",
      });
    }

    // Check if errors are empty
    if (errors.length === 0) {
      handlePasswordChange(password, e.target["reset-token"].value);
    } else {
      handleFormErrors(errors);
    }
  };

  let handleFormErrors = (obj = {}) => {
    setFormDisabled(false);
    setFormErrors(obj);
  };

  return (
    renderForm && (
      <StyledPasswordChangeForm>
        <h1 style={{ fontSize: "3rem", margin: "0 0 1rem" }}>
          Change Password
        </h1>
        <Form
          onSubmit={handleSubmit}
          submitLabel="Submit"
          disabled={formDisabled}
          isLoading={formDisabled}
          success={formSuccess}
          formErrors={formErrors}
        >
          <TextInput
            type="hidden"
            name="reset-token"
            id="reset-token"
            disabled={formDisabled}
            defaultValue={window.location.search.split("=")[1]}
          />
          <TextInput
            type="password"
            name="password"
            id="password"
            autoComplete="new-password"
            disabled={formDisabled}
            label="Password"
            error={formErrors && formErrors.find((o) => o.field === "password")}
          />
          <TextInput
            type="password"
            name="passwordConfirmation"
            id="passwordConfirmation"
            autoComplete="new-password"
            disabled={formDisabled}
            label="Confirm password"
            error={
              formErrors &&
              formErrors.find((o) => o.field === "passwordConfirmation")
            }
          />
        </Form>
      </StyledPasswordChangeForm>
    )
  );
}

export default PasswordChange;
