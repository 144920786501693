import React, { useState } from "react";
import api from "../../helpers/apiHelper";

import Form from "../ui/Form";
import TextInput from "../ui/TextInput";

const AddSnippet = (props) => {
  const [formErrors, setFormErrors] = useState(false);

  let addSnippet = async (name, description, code, team = null) => {
    setFormErrors(false);
    try {
      let response = await api.post("/snippets/create", {
        body: JSON.stringify({
          snippet: { name, description, code, team },
        }),
      });

      if (response.success) {
        props.updateSnippetsList([...props.snippetsList, response.snippet]);
      }
      return response;
    } catch (err) {
      console.log(err);
    }
    return false;
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    e.persist();
    let errors = [];

    let snippetName = e.target.name.value;
    let snippetDesc = e.target.description.value;
    let snippetCode = e.target.code.value;
    let snippetTeam = !!props.currentTeam.value
      ? props.currentTeam.value
      : null;

    if (snippetCode.length === 0) {
      errors.push({ field: "code", message: "Please enter a snippet!" });
    }
    // Check if errors are empty
    if (errors.length === 0) {
      try {
        let response = await addSnippet(
          snippetName,
          snippetDesc,
          snippetCode,
          snippetTeam
        );

        if (response && "error" in response) {
          errors.push(response.error.errors);
          handleFormErrors(response.error.errors);
        }

        if (response.success) {
          // clear form after successful submit.
          // can be better if we check on snippet success
          e.target.name.value = "";
          e.target.description.value = "";
          e.target.code.value = "";
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      handleFormErrors(errors);
    }
  };

  let handleFormErrors = (obj) => {
    setFormErrors(obj);
  };

  let addSnippetTeam = props.currentTeam.value
    ? ` to ${props.currentTeam.text}`
    : "";

  return (
    <Form
      onSubmit={handleSubmit}
      submitLabel={`Add snippet${addSnippetTeam}`}
      formErrors={formErrors}
      formTitle="Add a new snippet"
    >
      <TextInput
        name="name"
        id="name"
        label="Snippet Name"
        error={formErrors && formErrors.find((o) => o.field === "name")}
      />
      <TextInput
        name="description"
        id="description"
        label="Snippet Description"
        error={formErrors && formErrors.find((o) => o.field === "description")}
      />
      <TextInput
        type="code"
        name="code"
        id="code"
        label="Snippet Code"
        error={formErrors && formErrors.find((o) => o.field === "code")}
      />
    </Form>
  );
};

export default AddSnippet;
