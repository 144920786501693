export default {
  body: {
    small: "0.875rem",
    medium: "1rem",
    large: "2rem",
  },
  element: {
    small: "0.75rem",
    medium: "1rem",
    large: "2rem",
  },
  kerning: {
    loose: "0.05rem",
  },
  header: {
    small: "1.6rem",
    medium: "2rem",
    large: "4rem",
  },
};
