import React, { useState, useEffect } from "react";

import { useAuth } from "../../hooks/useAuth";

import {
  StyledNavigationContainer,
  StyledList,
  StyledListItem,
  StyledLink,
} from "./Navigation.styled";

const NavLink = (props) => {
  const { actionButton, ...rest } = props;
  return (
    <StyledListItem actionButton={actionButton}>
      <StyledLink {...rest}>{props.children}</StyledLink>
    </StyledListItem>
  );
};

const Navigation = () => {
  let authenticate = useAuth();

  const [renderNavButtons, setRenderNavButtons] = useState(false);

  useEffect(() => {
    if (authenticate.user != null) {
      setRenderNavButtons(true);
    }
  }, [authenticate.user]);

  return (
    <StyledNavigationContainer>
      <StyledList>
        <StyledListItem isLogo={true}>
          <StyledLink to={authenticate.user ? "/dashboard" : "/"}>
            <img
              src="img/browser-snippets-playful-logo.svg"
              alt="Browser Snippets"
              height="29px"
            />
          </StyledLink>
        </StyledListItem>
        {renderNavButtons && (
          <>
            {!authenticate.user ? (
              <>
                <NavLink to="/register">Register</NavLink>
                <NavLink to="/login" actionButton={true}>
                  Login
                </NavLink>
                {/* <NavLink to="/forgot">Forgot Password</NavLink> */}
              </>
            ) : (
              <>
                {window.location.pathname === "/" && (
                  <NavLink to="/dashboard">Dashboard</NavLink>
                )}
                <StyledListItem
                  to="/logout"
                  onClick={authenticate.logout}
                  actionButton={true}
                >
                  Logout
                </StyledListItem>
              </>
            )}
          </>
        )}
      </StyledList>
    </StyledNavigationContainer>
  );
};

export default Navigation;
