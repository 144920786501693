import React, { useState } from "react";
import api from "../../helpers/apiHelper";
import Colors from "../style/tokens/Colors";

// TODO:
// - Clean up snippets bits and refactor for reusability
// - Check if team owner for conditional buttons like delete (maybe add this to the backend response) e.g. {...teamStuff, owner: true}
// - Add Leave Team button
// - Add toggle content (invite url, refresh invite url, close group, list of users (if admin/owner), remove users(? this needs adding in backed))
// Create team

import {
  StyledTeamsList,
  StyledTeamsItem,
  StyledTeamsToggle,
  StyledTeamsTitle,
  StyledTeamsInnerArea,
  StyledTeamsInnerAreaColumn,
  StyledTeamsDescription,
  StyledTeamsInviteCode,
  // StyledTeamsEditCode,
  StyledTeamsMember,
  StyledTeamsRemoveMember,
  StyledTeamsDelete,
  StyledTeamsLeave,
  // StyledTeamsEdit,
  // StyledTeamsSave,
} from "../Teams/TeamsList.styled";

const TeamsList = (props) => {
  const [copiedInvite, setCopiedInvite] = useState(null);

  // TODO: Implement a refresh button for the invite code
  // const handleJoinKeyRefresh = () => {
  //   console.log("refresh invite code");
  // };
  // <small
  //   style={{
  //     display: "block",
  //     margin: "0.1rem 0 1rem",
  //     cursor: "pointer",
  //     textDecoration: "underline",
  //   }}
  //   onClick={handleJoinKeyRefresh}
  // >
  //   Refresh invite code
  // </small>

  const teams = props.teamsList.map((team, key) => {
    const isOwner = props.user.id === team.owner._id;
    const isLastMember =
      Array.isArray(team.members) && team.members.length === 0;
    // let isEditing = editingSnippet === snippet._id;

    return (
      <StyledTeamsItem key={key}>
        <StyledTeamsToggle>
          <StyledTeamsTitle>{team.name}</StyledTeamsTitle>
          <StyledTeamsDescription>
            {isOwner
              ? `You're owner of this team`
              : `You're a member of this team`}
          </StyledTeamsDescription>
          {isOwner && (
            <StyledTeamsInnerArea>
              <StyledTeamsInnerAreaColumn>
                <h3
                  style={{
                    margin: "0",
                  }}
                >
                  Members: ({team.members.length + 1})
                </h3>
                <ul
                  style={{
                    marginTop: "1rem",
                    marginBottom: "1.5rem",
                    paddingLeft: "1.8rem",
                  }}
                >
                  <li>{team.owner.username} (you)</li>
                  {team.members.map((member, key) => {
                    return (
                      <StyledTeamsMember key={key}>
                        <span
                          style={{
                            display: "block",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {member.username}
                        </span>
                        <StyledTeamsRemoveMember
                          confirmClick
                          onClick={() => {
                            removeTeamMember(member._id, team._id);
                          }}
                          confirmMessage={`Are you sure you want to remove "${member.username}" from "${team.name}"`}
                        >
                          remove
                        </StyledTeamsRemoveMember>
                      </StyledTeamsMember>
                    );
                  })}
                </ul>
              </StyledTeamsInnerAreaColumn>
              <StyledTeamsInnerAreaColumn>
                <h3
                  style={{
                    margin: "0",
                  }}
                >
                  Invite code:
                </h3>
                <small style={{ display: "block", margin: "1rem 0" }}>
                  Share this code with people to join your team
                </small>
                <StyledTeamsInviteCode
                  onCopy={() => {
                    setCopiedInvite(key);
                  }}
                  onClick={() => {
                    setCopiedInvite(null);
                  }}
                  copiedInvite={copiedInvite === key}
                >
                  {team.joinKey}
                </StyledTeamsInviteCode>
              </StyledTeamsInnerAreaColumn>
            </StyledTeamsInnerArea>
          )}
        </StyledTeamsToggle>
        <>
          <StyledTeamsDelete
            size={"small"}
            onClick={() => (isOwner ? deleteTeam(team._id) : undefined)}
            confirmClick={true}
            confirmMessage={`Are you sure you want to DELETE the team "${team.name}" for everyone?`}
            disabled={!isOwner}
            title={"Delete team"}
          >
            ✕
          </StyledTeamsDelete>
          <StyledTeamsLeave
            size={"small"}
            onClick={() => {
              leaveTeam(team._id);
            }}
            confirmClick={true}
            confirmMessage={`Are you sure you want to LEAVE the team "${team.name}"?`}
            disabled={isLastMember}
          >
            Leave
          </StyledTeamsLeave>
          {/* <StyledTeamsEdit
            size={"small"}
            onClick={() => setEditingSnippet(snippet._id)}
          >
            Edit
          </StyledTeamsEdit> */}
        </>
      </StyledTeamsItem>
    );
  });

  const deleteTeam = async (teamId) => {
    try {
      const response = await api.del("/teams/delete", {
        body: JSON.stringify({ team: { id: teamId } }),
      });
      if (response.success) {
        props.updateTeamsList(
          props.teamsList.filter((t) => {
            return t._id !== teamId;
          })
        );
      }
    } catch (err) {
      console.log("deleteTeam", err);
    }
  };

  const removeTeamMember = async (memberId, teamId) => {
    try {
      const response = await api.put("/teams/members/remove", {
        body: JSON.stringify({ team: { id: teamId, members: [memberId] } }),
      });

      if (response.success) {
        console.log(response);
        const newTeams = props.teamsList.map((t) => {
          if (t._id === teamId) {
            t.members = response.team.members;
          }
          return t;
        });

        props.updateTeamsList(newTeams);
      }
    } catch (err) {
      console.log("removeTeamMember", err);
    }
  };

  const leaveTeam = async (teamId) => {
    try {
      const response = await api.put("/teams/leave", {
        body: JSON.stringify({ team: { id: teamId } }),
      });
      if (response.success) {
        props.updateTeamsList(
          props.teamsList.filter((t) => {
            return t._id !== teamId;
          })
        );
      }
    } catch (err) {
      console.log("leaveTeam", err);
    }
  };

  return (
    <>
      {teams.length > 0 ? (
        <StyledTeamsList>{teams}</StyledTeamsList>
      ) : (
        <p
          style={{
            padding: "1rem",
            borderRadius: "5px",
            margin: "0.5rem 0.5rem 2.5rem",
            textAlign: "center",
            backgroundColor: `${Colors.basic.yellow}`,
          }}
        >
          <strong>It's looking a little empty in here!</strong>
          <br />
          Why not join a team or create a new one for your co-workers and
          friends?
        </p>
      )}
    </>
  );
};

export default TeamsList;
