export default {
  accent: {
    primary: "#333333",
  },
  text: {
    primary: "#333333",
    white: "#FFFFFF",
    warning: "#FDD855",
    invalid: "#FF7E7E",
    disabled: "#6B6B6B",
    valid: "#A2ECB2",
  },
  element: {
    primary: "#333333",
    white: "#FFFFFF",
    warning: "#FDD855",
    invalid: "#FF7E7E",
    disabled: "#EFEFEF", // use as a background color with text.disabled
    valid: "#A2ECB2",
  },
  basic: {
    black: "#333333",
    white: "#FFFFFF",
    blue: "#A1E7FE",
    green: "#A2ECB2",
    yellow: "#FDD855",
    red: "#FF7E7E",
  },
  bold: {
    blue: "#2AB0DD",
    green: "#39C958",
    yellow: "#FFC61A",
    red: "#CF4242",
  },
  grayscale: {
    light: "#DADADA",
    medium: "#BDBDBD",
    dark: "#484848",
  },
};
