import React, { useState } from "react";

import {
  CardWrapper,
  CardImageHeader,
  CardTextWrapper,
  CardTitle,
  CardBlurb,
  CardCta,
  CardImageHeaderImg,
} from "./Card.styled";

const Card = (props) => {
  const { imageUrl, title, blurb } = props;

  const [ctaVisible, setCtaVisible] = useState(false);

  // If the <Card> is given internal elements, these will be initally hidden
  // and shown when the card is interacted with (either hover or click)
  const hasChildren = !!props.children;

  return (
    <CardWrapper
      hasChildren={hasChildren}
      showCta={hasChildren && ctaVisible}
      onClick={(e) => {
        hasChildren && !ctaVisible ? setCtaVisible(true) : setCtaVisible(false);
      }}
    >
      <CardImageHeader>
        <CardImageHeaderImg src={imageUrl} />
      </CardImageHeader>
      <CardTextWrapper>
        <CardTitle>{title}</CardTitle>
        <CardBlurb>{blurb}</CardBlurb>
        {props.children && <CardCta>{props.children}</CardCta>}
      </CardTextWrapper>
    </CardWrapper>
  );
};

export default Card;
