import styled, { css } from "styled-components";
import Button from "../ui/Button/Button";
import Spacing from "../style/tokens/Spacing";
import Colors from "../style/tokens/Colors";
import Borders from "../style/tokens/Borders";
import Typography from "../style/tokens/Typography";

export const CardTextWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: left;
  padding: ${Spacing.medium};
  justify-content: center;
  transition: transform 0.3s;
  user-select: none;
`;

export const CardWrapper = styled.article`
  min-width: 14rem;
  width: 14rem;
  min-height: 16rem;
  height: 16rem;
  background-color: ${Colors.element.white};
  border-radius: ${Borders.radius.standard};
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: ${Spacing.medium};
  transition: all 0.2s;
  cursor: ${(props) => (props.hasChildren ? "pointer" : "auto")};

  &:hover {
    box-shadow: ${(props) =>
      props.hasChildren
        ? `0 0.5rem 0.45rem -0.375rem ${Colors.grayscale.dark}`
        : "none"};
  }

  ${(props) => {
    if (props.showCta) {
      return css`
        ${CardTextWrapper} {
          transform: translateX(-100%);
        }
      `;
    }
  }}
`;

export const CardImageHeader = styled.div`
  position: relative;
  width: 100%;
  min-height: 7.6rem;
  background-image: url("${(props) =>
    props.imageUrl ? props.imageUrl : "none"}");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const CardImageHeaderImg = styled.img`
  position: absolute;
  height: 100%;
  width: auto;
  max-width: none;
  left: 50%;
  transform: translateX(-50%);
`;

export const CardTitle = styled.h1`
  font-size: 1.2rem;
  margin: 0;
  padding: 0;
`;

export const CardBlurb = styled.p`
  font-size: ${Typography.body.small};
  margin: 0;
  padding: 0;
  margin-top: ${Spacing.tight};
`;

export const CardCta = styled.div`
  position: absolute;
  top: 0;
  left: 100%;
  height: 100%;
  width: 100%;
  padding: ${Spacing.medium};
  box-sizing: border-box;
`;
