import styled, { css, keyframes } from "styled-components";

import Spacing from "../../style/tokens/Spacing";
import Colors from "../../style/tokens/Colors";
import Typography from "../../style/tokens/Typography";
import Borders from "../../style/tokens/Borders";

const buttonCSS = css`
  display: block;
  width: 100%;

  font-family: inherit;
  color: inherit;
  text-align: center;
  box-sizing: inherit;
  text-transform: uppercase;
  font-size: 1em;
  letter-spacing: inherit;

  line-height: inherit;
  user-select: none;
  background: transparent;

  cursor: pointer;

  padding: 1em 1.2em;

  text-overflow: ellipsis;
  overflow: hidden;

  -webkit-appearance: none;
  outline: none;
  border: none;

  ${(props) =>
    props.showDropdownArrow
      ? css`
          padding-right: calc(1.2em + 1.2rem);
          &::after {
            content: "";
            position: absolute;
            top: 50%;
            right: 0.8rem;
            margin-top: -0.24rem;
            width: 0;
            height: 0;
            border-left: 0.25rem solid transparent;
            border-right: 0.25rem solid transparent;
            border-top: 0.4rem solid ${Colors.element.white};
            pointer-events: none;
          }
        `
      : ""}

  ${(props) =>
    props.disabled &&
    css`
      cursor: not-allowed;
    `}
`;

const rotate = keyframes`
  from { transform: translate(-50%, -50%) rotate(0deg) }
  to { transform: translate(-50%, -50%) rotate(360deg) }
`;

const shake = keyframes`
  0% { transform: translate(2px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(0px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(2px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(2px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
`;

export const StyledDivButton = styled.button`
  ${buttonCSS}
`;
export const StyledInputButton = styled.input`
  ${buttonCSS}
`;

export const StyledButtonContainer = styled.div`
  display: ${(props) => (props.inline ? "inline-block" : "block")};
  position: relative;
  margin-bottom: ${Spacing.medium};
  width: auto;
  box-sizing: border-box;

  font-family: "RedHatDisplay-Bold", sans-serif;
  color: ${Colors.text.white};
  font-size: ${Typography.element.small};
  letter-spacing: ${Typography.kerning.loose};
  line-height: 1;

  background-color: ${Colors.element.primary};
  border-radius: ${Borders.radius.standard};

  overflow: hidden;

  /* 
    TODO: 
    use focus-within here to add styling
    to button container when focused
  */

  ${(props) =>
    props.isDisabled &&
    css`
      background-color: ${Colors.element.disabled} !important;
      color: ${Colors.text.disabled} !important;
      cursor: not-allowed;
    `}

  ${(props) =>
    props.isLoading
      ? css`
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: ${Colors.element.primary};
          }

          &::after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 1rem;
            height: 1rem;

            border: 0.25rem solid ${Colors.element.primary};
            border-top: 0.25rem solid white;
            border-left: 0.25rem solid white;
            border-radius: 1rem;

            animation: ${rotate} 0.5s linear infinite;
            box-sizing: border-box;
          }
        `
      : ""}

  ${(props) => {
    if (props.success) {
      return css`
        background-color: transparent;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: ${Colors.bold.green};
        }

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
          width: 0.5rem;
          height: 1rem;
          border: none;
          border-bottom: 0.25rem solid ${Colors.element.white};
          border-right: 0.25rem solid ${Colors.element.white};
          border-radius: 0;
          animation: none;
          box-sizing: border-box;
        }
      `;
    }
    if (props.failure) {
      return css`
        animation: ${shake} 0.4s;
      `;
    }
  }}
`;
