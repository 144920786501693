import React from "react";

import {
  StyledDivButton,
  StyledInputButton,
  StyledButtonContainer,
} from "./Button.styled";

// Simple form component that's allowed to render it's own submit button
// Submit renders when 'submitLabel' and 'onSubmit' props are provided
const Button = (props) => {
  const handleClick = (e) => {
    // Stop any events bubbling up if we're inside a parent with it's own onClick
    e.stopPropagation();

    // onClick confirmation only works on <button> and not form submits
    if (!props.onClick) return;
    if (props.confirmClick) {
      let confirmation = window.confirm(
        props.confirmMessage || "Are you sure you want to do this?"
      );
      if (confirmation) {
        props.onClick();
      }
    } else {
      props.onClick();
    }
  };
  // Lets us render an input button instead, if type defined as submit
  let useInput = props.type && props.type === "submit";
  let StyledButton = useInput ? StyledInputButton : StyledDivButton;
  return (
    <StyledButtonContainer
      failure={props.failure}
      success={props.success}
      isLoading={props.isLoading}
      isDisabled={props.disabled}
      className={props.className}
      inline={props.inline}
    >
      <StyledButton
        type={useInput && props.type}
        value={props.value}
        onClick={!props.disabled ? handleClick : undefined}
        showDropdownArrow={props.showDropdownArrow}
        disabled={props.disabled}
        title={props.title}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
      >
        {props.children}
      </StyledButton>
    </StyledButtonContainer>
  );
};

export default Button;
