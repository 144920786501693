import React, { useState, useEffect } from "react";

import { useAuth } from "../../hooks/useAuth";
import userInput from "../../helpers/userInputHelper";

import Form from "../ui/Form";
import TextInput from "../ui/TextInput";

import { StyledPasswordResetForm } from "./PasswordResetForm.styled";

import { useNavigate, useLocation } from "react-router-dom";

function PasswordReset() {
  const [formDisabled, setFormDisabled] = useState(false);
  const [formSuccess, setFormSuccess] = useState(false);
  const [renderForm, setRenderForm] = useState(false);
  const [formErrors, setFormErrors] = useState(false);

  let navigate = useNavigate();
  let location = useLocation();
  let authenticate = useAuth();

  let { from } = location.state || { from: { pathname: "/" } };

  useEffect(() => {
    if (authenticate.user != null) {
      authenticate.user
        ? navigate(from, { replace: true })
        : setRenderForm(true);
    }
  }, [authenticate.user, from]);

  let handlePasswordReset = async (username) => {
    let errors = [];

    try {
      let response = await authenticate.resetPassword(username);
      if (response && !response.success) {
        errors.push({
          field: "username",
          message: response.message,
        });
      }

      // Check if errors are empty
      if (errors.length === 0 && response.success) {
        setFormSuccess(true);
        setFormDisabled(true);
      } else {
        handleFormErrors(errors);
      }
    } catch (err) {
      setFormDisabled(false);
      console.log(err);
    }
  };

  let handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(false);
    setFormDisabled(true);

    let errors = [];

    let username = e.target.username.value;

    if (!userInput.isValidUsername(username)) {
      errors.push({
        field: "username",
        message:
          username.length === 0
            ? "there's nothing there!"
            : "invalid characters",
      });
    }

    // Check if errors are empty
    if (errors.length === 0) {
      handlePasswordReset(username);
    } else {
      handleFormErrors(errors);
    }
  };

  let handleFormErrors = (obj) => {
    setFormDisabled(false);
    setFormErrors(obj);
  };

  return (
    renderForm && (
      <StyledPasswordResetForm>
        <h1 style={{ fontSize: "3rem", margin: "0 0 1rem" }}>Reset Password</h1>
        <Form
          onSubmit={handleSubmit}
          submitLabel="Submit"
          disabled={formDisabled}
          isLoading={formDisabled}
          success={formSuccess}
          formErrors={formErrors}
        >
          <TextInput
            type="text"
            name="username"
            id="username"
            autoComplete="username"
            disabled={formDisabled}
            label="Username"
            error={formErrors && formErrors.find((o) => o.field === "username")}
          />
        </Form>
      </StyledPasswordResetForm>
    )
  );
}

export default PasswordReset;
