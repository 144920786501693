import React from "react";
import styled from "styled-components";

import Spacing from "../style/tokens/Spacing";
import Typography from "../style/tokens/Typography";
import Button from "./Button/Button";

const StyledForm = styled.form`
  width: 100%;
  padding: ${Spacing.tight};
  box-sizing: border-box;
`;

// Form title
const StyledFormTitle = styled.h2`
  margin: 0;
  padding: 0 ${Spacing.tight};
  margin-bottom: ${Spacing.tight};
  font-size: ${Typography.header.small};
`;

const ErrorMessage = (props) => {
  const errors = props.errors.map((error, index) => (
    <li
      style={{ margin: "0.5rem 0", color: "tomato", fontSize: "0.8rem" }}
      key={index}
    >
      {error.field}: {error.message}{" "}
    </li>
  ));

  return (
    <>
      <h4 style={{ color: "tomato" }}>Please fix the following errors:</h4>
      <ul style={{ margin: "0", padding: "0 1rem 1rem 2rem" }}>{errors}</ul>
    </>
  );
};

// Simple form component that's allowed to render it's own submit button and title
// Title renders with 'formTitle' prop
// Errors can be passed in as 'formErrors' object {field:name, message:string}
// Submit button renders when 'submitLabel' and 'onSubmit' props are provided

const Form = (props) => {
  return (
    <>
      {props.formTitle && <StyledFormTitle>{props.formTitle}</StyledFormTitle>}
      <StyledForm onSubmit={props.onSubmit} {...props}>
        {props.children}

        {props.formErrors && <ErrorMessage errors={props.formErrors} />}

        {props.submitLabel && props.onSubmit && (
          <Button
            type="submit"
            value={props.submitLabel}
            disabled={props.disabled}
            isLoading={props.isLoading}
            success={props.success}
            failure={props.failure}
          />
        )}
      </StyledForm>
    </>
  );
};

export default Form;
