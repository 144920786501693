import React, { useState } from "react";

import styled from "styled-components";
import Button from "../Button/Button";
import Colors from "../../style/tokens/Colors";
import Spacing from "../../style/tokens/Spacing";
import Borders from "../../style/tokens/Borders";

const StyledDropdown = styled.div`
  display: ${(props) => (props.inline ? "inline-block" : "block")};
  position: relative;
`;
const StyledDropdownButton = styled(Button)`
  position: relative;
  margin: 0;
  max-width: 18.75rem;
  white-space: nowrap;
`;
const StyledDropdownContainer = styled.div`
  position: absolute;
  top: 100%;
  ${(props) => (props.alignDropdown === "right" ? `right: 0;` : `left:0;`)}
  margin: ${Spacing.medium} 0;

  width: 12rem;
  max-width: 18.75rem;
  max-height: 17rem;
  background-color: ${Colors.element.white};
  z-index: 1;
  margin-top: ${Spacing.tight};
  display: ${(props) => (props.open ? "block" : "none")};
  overflow-y: auto;

  margin-bottom: ${Spacing.medium};

  border: ${Borders.full.thin};
  border-radius: ${Borders.radius.standard};
  box-sizing: border-box;
`;
const StyledDropdownItemContainer = styled.ul`
  margin: 0;
  padding: 0;
`;

const StyledDropdownItem = styled.li`
  list-style: none;
  cursor: pointer;
  padding: ${Spacing.tight};
  color: ${Colors.accent.primary};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    color: ${Colors.text.white};
    background-color: ${Colors.accent.primary};
  }
`;

// Example options array
// const options = [
//   {
//     text: "Option 1",
//     value: "605670dcd0dc7a7f7c812528",
//   },
//   {
//     text: "Option 2",
//     value: "6057ceb212406941f498075f",
//   },
//   {
//     text: "Option 3",
//     value: "6057ceb912406941f4980760",
//   },
// ];

// Dropdown can be wrapped around other content to append it to the dropdown list (it uses props.children)
const Dropdown = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selected, setSelected] = useState(
    props.defaultSelection || props.options[0]
  );
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleSelection = (selection) => {
    setDropdownOpen(false);
    if (props.selected.value !== selection.value) {
      props.onSelect(selection);
      setSelected(selection);
    }
  };

  const dropdownItems = props.options.map((item, index) => {
    return (
      <StyledDropdownItem
        key={index}
        onClick={() => {
          handleSelection(item);
        }}
      >
        {item.text}
      </StyledDropdownItem>
    );
  });

  return (
    <StyledDropdown inline={props.inline}>
      <StyledDropdownButton onClick={toggleDropdown} showDropdownArrow={true}>
        {selected.text}
      </StyledDropdownButton>
      <StyledDropdownContainer
        open={dropdownOpen}
        alignDropdown={props.alignDropdown}
      >
        <StyledDropdownItemContainer>
          {dropdownItems}
        </StyledDropdownItemContainer>
        {props.children}
      </StyledDropdownContainer>
    </StyledDropdown>
  );
};

export default Dropdown;
