import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

import Colors from "../style/tokens/Colors";
import Spacing from "../style/tokens/Spacing";

export const StyledNavigationContainer = styled.nav`
  width: 100%;
  padding: ${Spacing.loose};
  text-align: right;
  box-sizing: border-box;
`;

export const StyledList = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const StyledListItem = styled.li`
  display: inline-block;
  margin: 0;
  padding: ${Spacing.tight};
  list-style: none;

  ${(props) => props.actionButton && actionButtonStyles}
  ${(props) => props.isLogo && logoStyles}
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  ${(props) => props.actionButton && actionButtonStyles}
`;

const logoStyles = css`
  margin-right: auto;
  max-width: 18rem;
  height: auto;
  ${StyledLink} {
    display: block;
    font-size: 0;
  }
`;
const actionButtonStyles = css`
  cursor: pointer;
  border-radius: 3px;
  margin-left: ${Spacing.tight};
  background-color: ${Colors.basic.blue};
  &:hover {
    background-color: ${Colors.basic.green};
  }
`;
