import React, { useState } from "react";
import api from "../../helpers/apiHelper";
import Colors from "../style/tokens/Colors";

import {
  StyledSnippetsList,
  StyledSnippetsItem,
  StyledSnippetsToggle,
  StyledSnippetsTitle,
  StyledSnippetsDescription,
  StyledSnippetsCode,
  StyledSnippetsEditCode,
  StyledSnippetsDelete,
  StyledSnippetsEdit,
  StyledSnippetsSave,
} from "./SnippetsList.styled";

const SnippetsList = (props) => {
  const [editingSnippet, setEditingSnippet] = useState("");

  const snippetItems = props.snippetsList.map((snippet, key) => {
    const isAuthor = props.user.id === snippet.user;
    const isEditing = editingSnippet === snippet._id;

    return (
      <StyledSnippetsItem editing={isEditing} key={key}>
        <StyledSnippetsToggle open={isEditing}>
          <StyledSnippetsTitle>{snippet.name}</StyledSnippetsTitle>
          {snippet.description && (
            <StyledSnippetsDescription>
              {snippet.description}
            </StyledSnippetsDescription>
          )}

          <pre>
            {isEditing ? (
              <StyledSnippetsEditCode
                id={`edit-snippet-${snippet._id}`}
                defaultValue={snippet.code}
              />
            ) : (
              <StyledSnippetsCode>{snippet.code}</StyledSnippetsCode>
            )}
          </pre>
        </StyledSnippetsToggle>
        {isEditing ? (
          <StyledSnippetsSave
            size={"small"}
            onClick={async () => await updateSnippet(snippet.code, snippet._id)}
            title="Save snippet"
          >
            Save
          </StyledSnippetsSave>
        ) : (
          <>
            <StyledSnippetsDelete
              size={"small"}
              onClick={() => deleteSnippet(snippet._id)}
              confirmClick={true}
              confirmMessage="Are you sure you want to DELETE this snippet?"
              title="Delete snippet"
              disabled={!isAuthor}
            >
              ✕
            </StyledSnippetsDelete>
            <StyledSnippetsEdit
              size={"small"}
              onClick={() => setEditingSnippet(snippet._id)}
              title="Edit snippet"
              disabled={!isAuthor}
            >
              Edit
            </StyledSnippetsEdit>
          </>
        )}
      </StyledSnippetsItem>
    );
  });

  let updateSnippet = async (prevSnippetCode, id) => {
    let code = document.getElementById(`edit-snippet-${id}`).value;

    // Don't change if snippet is empty or unchanged
    if (code.length === 0 || prevSnippetCode === code) {
      return setEditingSnippet("");
    }

    try {
      let response = await api.put("/snippets/edit", {
        body: JSON.stringify({ snippet: { id, code } }),
      });

      if (response.success) {
        // Find the snippet object index in userSnippets array
        let snippetIndex = props.snippetsList.findIndex((s) => {
          return s._id === response.snippet._id;
        });

        // Get existing snippet array and replace it's code
        let updatedSnippets = [...props.snippetsList];
        updatedSnippets[snippetIndex].code = response.snippet.code;

        props.updateSnippetsList([...updatedSnippets], setEditingSnippet(""));
      }
    } catch (err) {
      console.log(err);
      setEditingSnippet("");
      alert("Snippet not saved, please try again");
    }
  };

  let deleteSnippet = async (snippetId) => {
    try {
      const response = await api.del("/snippets/delete", {
        body: JSON.stringify({ snippet: { id: snippetId } }),
      });

      if (response.success) {
        props.updateSnippetsList(
          props.snippetsList.filter((s) => {
            return s._id !== snippetId;
          })
        );
      }
    } catch (err) {
      console.log("deleteSnippet", err);
    }
  };

  return snippetItems.length > 0 ? (
    <StyledSnippetsList>{snippetItems}</StyledSnippetsList>
  ) : (
    <p
      style={{
        padding: "1rem",
        borderRadius: "5px",
        margin: "1rem 0.5rem 2rem",
        textAlign: "center",
        backgroundColor: `${Colors.basic.yellow}`,
      }}
    >
      <strong>No snippets? No Problem!</strong>
      <br />
      <br />
      Add your first snippet below.
      <br />
      <br />
      Any snippets you add here will appear in your <em>
        Browser Snippets
      </em>{" "}
      extension.
      <br />
      Before you begin,{" "}
      <a
        href="https://chrome.google.com/webstore/detail/browser-snippets/ofbdafhoclkjahpdhpmnfnppnepihmgi/"
        rel="noopener noreferrer"
        target="_blank"
      >
        download Browser Snippets for Chrome here.
      </a>
    </p>
  );
};

export default SnippetsList;
