import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Dropdown from "../ui/Dropdown/Dropdown";
import Button from "../ui/Button/Button";

import api from "../../helpers/apiHelper";
import Colors from "../style/tokens/Colors";

const StyledManageTeamButton = styled(Button)`
  margin: 10px;

  &:hover {
    background-color: ${Colors.bold.green};
    color: ${Colors.text.white};
  }
`;
const TeamSwitcher = (props) => {
  // Move into teams component
  const [userTeams, setUserTeams] = useState([]);
  const [userTeamsLoaded, setUserTeamsLoaded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getTeams = async () => {
      try {
        let response = await api.get("/teams");
        // Sets the first dropdown choice as the user's private snippets
        let teamSwitcherOptions = [{ text: "My snippets", value: "" }];
        if (response.success) {
          response.teams.forEach((team) => {
            teamSwitcherOptions.push({
              text: team.name,
              value: team._id,
            });
          });
          setUserTeams(teamSwitcherOptions);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setUserTeamsLoaded(true);
      }
    };

    getTeams();
  }, []);

  return (
    userTeamsLoaded && (
      <div style={props.style}>
        <>
          <small style={{ display: "block", marginBottom: "0.5rem" }}>
            Current team:
          </small>
          <Dropdown
            inline
            alignDropdown={props.alignDropdown}
            selected={props.currentTeam}
            options={userTeams}
            onSelect={props.changeTeam}
          >
            <StyledManageTeamButton
              onClick={() => {
                navigate("/manage-teams");
              }}
            >
              Manage teams
            </StyledManageTeamButton>
          </Dropdown>
        </>
      </div>
    )
  );
};

export default TeamSwitcher;
