import React, { useState } from "react";
import api from "../../helpers/apiHelper";

import Form from "../ui/Form";
import TextInput from "../ui/TextInput";

const CreateTeam = (props) => {
  const [formErrors, setFormErrors] = useState(false);

  let createTeam = async (name) => {
    setFormErrors(false);
    try {
      let response = await api.post("/teams/create", {
        body: JSON.stringify({
          team: { name },
        }),
      });

      if (response.success) {
        props.updateTeamsList([...props.teamsList, response.team]);
      }
      return response;
    } catch (err) {
      console.log(err);
    }
    return false;
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    e.persist();
    let errors = [];

    let teamName = e.target.name.value;

    if (teamName.length === 0) {
      errors.push({ field: "name", message: "Please enter a team name!" });
    }
    // Check if errors are empty
    if (errors.length === 0) {
      try {
        let response = await createTeam(teamName);

        if (response && "error" in response) {
          errors.push(response.error.errors);
          handleFormErrors(response.error.errors);
        }

        if (response.success) {
          // clear form after successful submit.
          e.target.name.value = "";
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      handleFormErrors(errors);
    }
  };

  let handleFormErrors = (obj) => {
    setFormErrors(obj);
  };

  return (
    <Form
      onSubmit={handleSubmit}
      submitLabel={"Create a new team"}
      formErrors={formErrors}
      formTitle="Create a new team"
    >
      <TextInput
        name="name"
        id="name"
        label="Team Name"
        error={formErrors && formErrors.find((o) => o.field === "name")}
        style={{ marginTop: "auto" }}
      />
    </Form>
  );
};

export default CreateTeam;
