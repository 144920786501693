import styled, { css } from "styled-components";
import Button from "../ui/Button/Button";
import Spacing from "../style/tokens/Spacing";
import Colors from "../style/tokens/Colors";
import Borders from "../style/tokens/Borders";

const sectionStyles = css`
  padding: ${Spacing.loose};
  padding-top: 0;
  box-sizing: border-box;
`;

const buttonStyles = css`
  margin: 0.5rem;
  border: ${Borders.full.thin};
`;

export const StyledIntro = styled.section`
  ${sectionStyles}
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  min-height: 85vh;
`;

export const StyledIntroHeader = styled.h1`
  line-height: 1;
  margin: 0 auto;
  padding: 0;
  text-align: center;

  @media (min-width: 50rem) {
    font-size: 4rem;
    max-width: 46rem;
  }
`;

export const StyledSubtitle = styled.p`
  line-height: 1.4;

  margin: 1rem auto;
  text-align: center;
  @media (min-width: 50rem) {
    font-size: 1.2rem;
    max-width: 50rem;
  }
`;

export const StyledOutro = styled.section`
  ${sectionStyles}
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  padding: 6rem 2rem;

  min-height: 85vh;
`;

const h2Styles = css`
  line-height: 1;
  margin: 0 auto;
  padding: 0;
  text-align: center;

  @media (min-width: 50rem) {
    font-size: 2.6rem;
    max-width: 46rem;
  }
`;
export const StyledOutroTitle = styled.h2`
  ${h2Styles}
`;

export const StyledDemoWebsiteColumnTitle = styled.h2`
  ${h2Styles}
`;

export const StyledButtonContainer = styled.div`
  text-align: center;
`;

export const StyledExamplesButton = styled(Button)`
  ${buttonStyles}

  background-color: transparent;
  color: ${Colors.text.primary};

  &:hover {
    color: ${Colors.text.white};
    background-color: ${Colors.bold.green};
    border-color: ${Colors.bold.green};
  }
`;

export const StyledGetStartedButton = styled(Button)`
  ${buttonStyles}

  &:hover {
    color: ${Colors.text.white};
    background-color: ${Colors.bold.blue};
    border-color: ${Colors.bold.blue};
  }
`;

export const StyledCardButton = styled(Button)`
  ${buttonStyles}

  &:hover {
    color: ${Colors.text.white};
    background-color: ${Colors.bold.blue};
    border-color: ${Colors.bold.blue};
  }
`;

export const StyledBrowserSnippetsDemo = styled(StyledIntro)`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${Colors.basic.yellow};
  width: 100%;
  text-align: center;
  align-items: center;
  padding: ${Spacing.loose};
  box-sizing: border-box;
  min-height: 90vh;
  overflow: hidden;

  @media (min-width: 40rem) {
    flex-direction: row;
    height: 90vh;
  }
`;

export const StyledDemoSnippetsColumn = styled.div`
  display: flex;
  flex-direction: row;
  max-height: 100%;
  overflow: auto;
  width: 100%;
  @media (min-width: 40rem) {
    max-height: 90%;
    min-width: 17rem;
    width: 17rem;
    flex-direction: column;
  }

  &::-webkit-scrollbar {
    background-color: transparent;
    padding: 0;
    margin: 0;
    height: 0.6rem;
    width: 0.6rem;
    outline: none;
    box-sizing: border-box;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${Colors.element.primary};
    border-radius: ${Borders.radius.pill};
    cursor: pointer;
    outline: none;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
`;
export const StyledDemoWebsiteColumn = styled.div`
  display: flex;
  max-width: 50rem;
  padding: ${Spacing.loose} 0;
  order: -1;

  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  @media (min-width: 40rem) {
    order: 2;
    padding-left: 0;
    margin-left: ${Spacing.loose};
  }
`;

export const StyledWebsiteImage = styled.div`
  background-image: url("img/examples/evil-cats-club-website.png");
  background-size: contain;

  ${(props) => {
    if (props.snippetAction) {
      if (props.snippetAction === "blur") {
        return css`
          filter: blur(2px);
        `;
      }
      if (props.snippetAction === "grayscale") {
        return css`
          filter: grayscale(1);
        `;
      }
      if (props.snippetAction === "invert") {
        return css`
          filter: invert(1);
        `;
      }
    }
  }}
`;

export const StyledDemoWebsite = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 60%;
  height: 0;
  background-color: ${Colors.element.white};
  border: ${Borders.full.medium};
  overflow: hidden;
  border-radius: 1rem;
  padding-top: 2rem;

  &:before {
    content: "•••";
    color: ${Colors.grayscale.medium};
    font-size: 4rem;
    line-height: 0;
    padding-left: 0.25rem;
    height: 1rem;
    text-align: left;
    position: absolute;
    width: 100%;
    top: 1rem;
    left: 0;
    border-bottom: ${Borders.full.medium};
    z-index: 1;
  }

  ${StyledWebsiteImage} {
    position: absolute;
    top: 2.25rem;
    width: 101%;
    height: 100%;
    bottom: 0;
    left: 0;
    border: none;
    outline: none;
  }
`;

export const FeatureListWrapper = styled.ul`
  margin: 2rem auto 2.5rem;
  padding: 0;
  display: block;
  text-align: center;
  list-style: none;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: top;
  max-width: 50rem;

  @media (min-width: 50rem) {
    display: flex;
    margin: 3rem auto;
  }
`;

export const FeatureListItem = styled.li`
  margin: 0 auto 2rem;
  padding: 0;

  text-align: center;

  strong {
    margin-bottom: auto;
    display: block;
    margin-bottom: 0.3rem;
    font-size: 1.2rem;

    @media (min-width: 50rem) {
      font-size: 1.4rem;
    }
  }
  @media (min-width: 50rem) {
    display: flex;
    flex-direction: column;
    min-width: 13rem;
    width: 13rem;
    margin-bottom: 1rem;
  }
`;
