import auth from "../managers/authManager";

const api = () => {
  let domain = window.location.hostname;
  let tld = "com";

  if (domain.indexOf("browsersnippets.dev") !== -1) {
    tld = "dev";
  }

  let baseApiUrl = `https://api.browsersnippets.${tld}/api`;

  let contentHeader = {
    "Content-Type": "application/json; charset=utf-8",
    Accept: "application/json",
  };

  const get = async (url, sendToken = true) => {
    let bearerToken = sendToken
      ? {
          Authorization: `Bearer ${auth.getToken()}`,
        }
      : {};

    try {
      let response = await fetch(`${baseApiUrl}${url}`, {
        method: "GET",
        credentials: "include",
        mode: "cors",
        headers: {
          ...bearerToken,
          ...contentHeader,
        },
      });
      let data = await response.json();

      if (await silentRefresh(data)) {
        data = await get(url);
      }

      return data;
    } catch (err) {
      console.log(err);
    }
  };

  const post = async (url, postContent, sendToken = true) => {
    let bearerToken = sendToken
      ? {
          Authorization: `Bearer ${auth.getToken()}`,
        }
      : {};

    try {
      let response = await fetch(`${baseApiUrl}${url}`, {
        method: "POST",
        credentials: "include",
        mode: "cors",
        headers: {
          ...bearerToken,
          ...contentHeader,
        },
        ...postContent,
      });
      let data = await response.json();

      if (await silentRefresh(data)) {
        data = await post(url, postContent);
      }

      return data;
    } catch (err) {
      console.log(err);
    }
  };

  const put = async (url, putContent, sendToken = true) => {
    let bearerToken = sendToken
      ? {
          Authorization: `Bearer ${auth.getToken()}`,
        }
      : {};

    try {
      let response = await fetch(`${baseApiUrl}${url}`, {
        method: "PUT",
        credentials: "include",
        mode: "cors",
        headers: {
          ...bearerToken,
          ...contentHeader,
        },
        ...putContent,
      });
      let data = await response.json();

      if (await silentRefresh(data)) {
        data = await put(url, putContent);
      }

      return data;
    } catch (err) {
      console.log(err);
    }
  };

  const del = async (url, delContent, sendToken = true) => {
    let bearerToken = sendToken
      ? {
          Authorization: `Bearer ${auth.getToken()}`,
        }
      : {};

    try {
      let response = await fetch(`${baseApiUrl}${url}`, {
        method: "DELETE",
        credentials: "include",
        mode: "cors",
        headers: {
          ...bearerToken,
          ...contentHeader,
        },
        ...delContent,
      });
      let data = await response.json();

      if (await silentRefresh(data)) {
        data = await del(url, delContent);
      }

      return data;
    } catch (err) {
      console.log(err);
    }
  };

  const silentRefresh = async (response) => {
    // Checks if user's token has expired and attempts to do a silent refresh
    try {
      if (
        response &&
        "error" in response &&
        response.error.message === "Token expired" &&
        response.error.status === 401
      ) {
        let refresh = await auth.refreshTokens();

        if (refresh.success) {
          return true;
        }
      }
    } catch (err) {
      console.log("silentRefresh", err);
    }

    return false;
  };

  return {
    get,
    post,
    put,
    del,
  };
};

export default api();
