import styled, { css } from "styled-components";
import Spacing from "../style/tokens/Spacing";
import Borders from "../style/tokens/Borders";
import Colors from "../style/tokens/Colors";
import Typography from "../style/tokens/Typography";
import Button from "../ui/Button/Button";

// TODO: Move these styles into Button as an alternative "inline" version
const hideButtonWidth = "30rem";
const snippetsButtonCSS = css`
  width: auto;
  position: absolute;
  top: 0.45rem;
  margin: 0;
  cursor: pointer;
  background-color: transparent;
  color: ${Colors.text.primary};
`;

export const StyledSnippetsList = styled.ul`
  margin: 0;
  margin-bottom: ${Spacing.medium};
  padding: ${Spacing.tight};
  box-sizing: border-box;
`;

export const StyledSnippetsDelete = styled(Button)`
  ${snippetsButtonCSS}
  right: 0.45rem;

  @media (min-width: ${hideButtonWidth}) {
    display: none;
    &:hover {
      color: ${Colors.text.white};
      background-color: ${Colors.bold.red};
    }
  }
`;

export const StyledSnippetsEdit = styled(Button)`
  ${snippetsButtonCSS}
  right: 3.25rem;
  @media (min-width: ${hideButtonWidth}) {
    display: none;
    &:hover {
      color: ${Colors.text.white};
      background-color: ${Colors.bold.blue};
    }
  }
`;

export const StyledSnippetsSave = styled(Button)`
  ${snippetsButtonCSS}

  display: block;
  /* right: 0.45rem; */
  right: 3.25rem;

  @media (min-width: ${hideButtonWidth}) {
    &:hover {
      color: ${Colors.text.white};
      background-color: ${Colors.bold.green};
    }
  }
`;

export const StyledSnippetsItem = styled.li`
  position: relative;
  margin: 0;
  margin-bottom: ${Spacing.medium};
  padding: 0;
  list-style: none;

  width: 100%;

  border-radius: ${Borders.radius.standard};

  ${(props) =>
    props.editing
      ? `border: 1px solid ${Colors.bold.yellow};`
      : `border: 1px solid ${Colors.grayscale.light};`}

  box-sizing: border-box;
  transition: all 0.2s;
  overflow: hidden;

  &:hover {
    background-color: #fff;
    box-shadow: 0 0.5rem 0.45rem -0.375rem ${Colors.grayscale.light};

    ${StyledSnippetsDelete},
    ${StyledSnippetsEdit} {
      display: block;
    }

    &::before {
      content: "";
      position: absolute;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      border-radius: ${Borders.radius.standard};
      pointer-events: none;
    }
  }
`;

export const StyledSnippetsToggle = styled.details`
  display: block;
  width: 100%;
`;

export const StyledSnippetsTitle = styled.summary`
  width: 100%;
  padding: ${Spacing.medium};
  padding-right: 6.25rem;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &:focus {
    outline: none;
  }
`;

export const StyledSnippetsDescription = styled.p`
  font-size: ${Typography.body.small};
  margin-top: 0;
  padding: 0 ${Spacing.loose};
  box-sizing: border-box;
`;

export const StyledSnippetsCode = styled.code`
  overflow: auto;
  max-height: 20rem;
  display: block;
  font-size: 0.8rem;
  padding: ${Spacing.medium};
  margin: ${Spacing.medium};
  margin-top: 0;
  border-radius: ${Borders.radius.standard};
  background-color: #eee;
  box-sizing: border-box;
`;

export const StyledSnippetsEditCode = styled.textarea`
  width: calc(100% - 2rem);
  max-width: calc(100% - 2rem);
  border: none;
  max-height: 20rem;
  display: block;
  font-size: 0.8rem;
  padding: ${Spacing.medium};
  margin: ${Spacing.medium};
  margin-top: 0;
  border-radius: ${Borders.radius.standard};
  background-color: #eee;
  box-sizing: border-box;

  border-radius: ${Borders.radius.standard};
  border: ${Borders.full.thin};

  :focus {
    outline: none;
  }
`;
