// Hook (useAuth.js) more information about this hook here: https://usehooks.com/useAuth/
import React, { useState, useEffect, useContext, createContext } from "react";
import auth from "../managers/authManager";

const authContext = createContext();

// Provider component that wraps your app and makes auth object
// available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object
// and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUserState] = useState(null);

  // Wrap any auth methods we want to use making sure
  // to save the user to state.
  const login = async (username, password) => {
    let response = {};
    try {
      response = await auth.requestTokens(username, password);

      if (response && !("error" in response)) {
        auth.setToken(response.accessToken);
      }
    } catch (err) {
      // console.log(err);
    } finally {
      setUser();
    }

    return response;
  };

  const signup = async (username, email, password) => {
    let response = {};
    try {
      response = await auth.createUser(username, email, password);
    } catch (err) {
      // console.log(err);
    }

    return response;
  };

  const logout = async () => {
    try {
      await auth.deleteTokens({ refresh: true });
    } catch (err) {
      // console.log(err);
    } finally {
      setUser(false);
    }
  };

  const setUser = async (fetchUser = true) => {
    if (!fetchUser) return setUserState(false);

    // get user and set it on user state
    try {
      let user = await auth.getUser();
      if (user) {
        setUserState(user);
      } else {
        throw new Error("User not found");
      }
    } catch (err) {
      // console.log(err);
      setUserState(false);
    }
  };

  const resetPassword = async (username) => {
    try {
      // sets password reset token and sends email to user
      let response = await auth.resetPassword(username);
      return response;
    } catch (err) {
      // console.log(err);
    }

    return false;
  };

  const confirmPasswordReset = async (password, resetToken) => {
    try {
      let response = await auth.confirmPasswordReset(password, resetToken);
      return response;
    } catch (err) {
      // console.log(err);
    }

    return false;
  };

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    async function checkIfLoggedIn() {
      try {
        let userHasRefreshToken = await auth.hasRefreshToken();
        if (userHasRefreshToken) {
          await auth.refreshTokens();
        }
        setUser(!!auth.getToken());
      } catch (err) {
        // console.log(err);
      }
    }

    checkIfLoggedIn();
  }, []);

  // Return the user object and auth methods
  return {
    user,
    login,
    signup,
    logout,
    // refresh,
    resetPassword,
    confirmPasswordReset,
  };
}
