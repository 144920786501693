import React, { useState } from "react";
import api from "../../helpers/apiHelper";

import Form from "../ui/Form";
import TextInput from "../ui/TextInput";

const JoinTeam = (props) => {
  const [formErrors, setFormErrors] = useState(false);

  let joinTeam = async (joinKey) => {
    setFormErrors(false);
    try {
      let response = await api.put("/teams/join", {
        body: JSON.stringify({
          team: { joinKey },
        }),
      });

      if (response.success) {
        props.updateTeamsList([...props.teamsList, response.team]);
      }
      return response;
    } catch (err) {
      console.log(err);
    }
    return false;
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    e.persist();
    let errors = [];

    let joinKey = e.target.invite.value;

    if (joinKey.length === 0) {
      errors.push({
        field: "invite",
        message: "Please enter a team's invite key",
      });
    }

    // Check if errors are empty
    if (errors.length === 0) {
      try {
        let response = await joinTeam(joinKey);

        if (response && "error" in response) {
          if (response.error.status && response.error.status === 409) {
            errors.push({
              field: "invite",
              message: "You're already on that team!",
            });
          } else {
            errors.push({
              field: "invite",
              message:
                "Something went wrong! Make sure the team exists and try again.",
            });
          }
          handleFormErrors(errors);
        }

        if (response.success) {
          // clear form after successful submit.
          e.target.invite.value = "";
        }
      } catch (err) {
        console.log("err", err);
      }
    } else {
      handleFormErrors(errors);
    }
  };

  let handleFormErrors = (obj) => {
    setFormErrors(obj);
  };

  return (
    <Form
      onSubmit={handleSubmit}
      submitLabel={"Join an existing team"}
      formErrors={formErrors}
      formTitle="Join an existing team"
    >
      <TextInput
        name="invite"
        id="invite"
        label="Enter team's invite key"
        error={formErrors && formErrors.find((o) => o.field === "invite")}
        style={{ marginTop: "auto" }}
      />
    </Form>
  );
};

export default JoinTeam;
