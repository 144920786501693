import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import api from "../../helpers/apiHelper";

import TeamsList from "./TeamsList";
import JoinTeam from "./JoinTeam";
import CreateTeam from "./CreateTeam";

const StyledAddTeamContainer = styled.div`
  @media (min-width: 40rem) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const StyledAddTeamColumn = styled.div`
  @media (min-width: 40rem) {
    display: flex;
    flex-direction: column;
    width: 48%;
  }
`;

const ManageTeams = (props) => {
  const [userTeamsLoaded, setLoadedUserTeams] = useState(false);
  const [userTeams, setUserTeams] = useState({});

  // User's private Teams
  useEffect(() => {
    const getTeams = async () => {
      try {
        let response = await api.get("/teams");
        if (response.success) {
          setUserTeams([...response.teams]);
          setLoadedUserTeams(true);
        }
      } catch (err) {
        console.error(err);
      }
    };

    getTeams();
  }, []);

  let handleTeamsListUpdate = (teamsList, cb) => {
    setUserTeams(teamsList, cb);
  };

  return (
    userTeamsLoaded && (
      <div style={{ padding: "2rem", maxWidth: "50rem", margin: "auto" }}>
        <div
          style={{
            padding: "0.5rem",
          }}
        >
          <small>
            <Link to="/dashboard">Back to snippets</Link>
          </small>
          <h1>Manage teams</h1>
        </div>

        <TeamsList
          user={props.auth.user}
          teamsList={userTeams}
          updateTeamsList={handleTeamsListUpdate}
        />
        <StyledAddTeamContainer>
          <StyledAddTeamColumn>
            <JoinTeam
              teamsList={userTeams}
              updateTeamsList={handleTeamsListUpdate}
            />
          </StyledAddTeamColumn>
          <StyledAddTeamColumn>
            <CreateTeam
              teamsList={userTeams}
              updateTeamsList={handleTeamsListUpdate}
            />
          </StyledAddTeamColumn>
        </StyledAddTeamContainer>
      </div>
    )
  );
};

export default ManageTeams;
