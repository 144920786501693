import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

import Card from "../Card/Card";

import {
  StyledIntro,
  StyledIntroHeader,
  StyledSubtitle,
  StyledButtonContainer,
  StyledExamplesButton,
  StyledGetStartedButton,
  StyledBrowserSnippetsDemo,
  StyledCardButton,
  StyledDemoSnippetsColumn,
  StyledDemoWebsiteColumn,
  StyledDemoWebsiteColumnTitle,
  StyledDemoWebsite,
  StyledWebsiteImage,
  StyledOutro,
  StyledOutroTitle,
  FeatureListWrapper,
  FeatureListItem,
} from "./Homepage.styled";

function Homepage() {
  const auth = useAuth();
  const navigate = useNavigate();

  const date = new Date();
  const currentYear = date.getFullYear();

  const handleLogin = () => {
    navigate("/login");
  };

  const handleCopySnippet = async () => {
    // Placeholder whoopsie alert while we get this built.
    alert(
      "Whoopsie! Thanks for trying out the Copy snippet button. We're still working on the finishing touches, but in the meantime, drop us a tweet at @browsersnippets and let us know what other snippets you'd like to use."
    );
  };

  const [snippetAction, setSnippetAction] = useState(null);

  return (
    <>
      <StyledIntro>
        <StyledIntroHeader>
          Run reusable snippets of code on any site
        </StyledIntroHeader>
        <StyledSubtitle>
          Easily automate actions or manipuate the DOM on any site with
          JavaScript.
        </StyledSubtitle>
        <StyledButtonContainer>
          <StyledExamplesButton
            inline={true}
            onClick={() => {
              document
                .getElementById("bs-examples")
                .scrollIntoView({ behavior: "smooth" });
            }}
          >
            See examples
          </StyledExamplesButton>
          <StyledGetStartedButton
            inline={true}
            onClick={() => navigate("/register")}
          >
            Get started for free
          </StyledGetStartedButton>
        </StyledButtonContainer>
      </StyledIntro>

      <StyledBrowserSnippetsDemo id="bs-examples">
        <StyledDemoSnippetsColumn>
          <Card
            imageUrl="img/examples/cat-low-vision.jpg"
            title="Low vision experience"
            blurb="Accessibility snippet to experience a website similar to a user with
          low vision."
          >
            <StyledCardButton
              onClick={() => {
                setSnippetAction("blur");
              }}
            >
              Run snippet
            </StyledCardButton>

            {/* If the user is logged in, allow them to save the snippet to their account */}
            {auth.user ? (
              <StyledCardButton onClick={handleCopySnippet}>
                Copy to snippets
              </StyledCardButton>
            ) : (
              <StyledCardButton onClick={handleLogin}>
                Login to save
              </StyledCardButton>
            )}
          </Card>
          <Card
            imageUrl="img/examples/cat-color-blind.jpg"
            title="Monochrome filter"
            blurb="Removes colour to visually demonstrate which elements need more contrast."
          >
            <StyledCardButton
              onClick={() => {
                setSnippetAction("grayscale");
              }}
            >
              Run snippet
            </StyledCardButton>

            {/* If the user is logged in, allow them to save the snippet to their account */}
            {auth.user ? (
              <StyledCardButton onClick={handleCopySnippet}>
                Copy to snippets
              </StyledCardButton>
            ) : (
              <StyledCardButton onClick={handleLogin}>
                Login to save
              </StyledCardButton>
            )}
          </Card>
          <Card
            imageUrl="img/examples/cat-invert.jpg"
            title="Invert Colours"
            blurb="Aids night blindess and increase contrasts by inverting colours."
          >
            <StyledCardButton
              onClick={() => {
                setSnippetAction("invert");
              }}
            >
              Run snippet
            </StyledCardButton>

            {/* If the user is logged in, allow them to save the snippet to their account */}
            {auth.user ? (
              <StyledCardButton onClick={handleCopySnippet}>
                Copy to snippets
              </StyledCardButton>
            ) : (
              <StyledCardButton onClick={handleLogin}>
                Login to save
              </StyledCardButton>
            )}
          </Card>
        </StyledDemoSnippetsColumn>
        <StyledDemoWebsiteColumn>
          <StyledDemoWebsiteColumnTitle>
            Simple, helpful snippets
          </StyledDemoWebsiteColumnTitle>
          <StyledSubtitle style={{ marginTop: "0.5rem", marginBottom: "2rem" }}>
            Tap on the tiles to try a selection of accessibility focused
            snippets.
          </StyledSubtitle>
          <StyledDemoWebsite>
            <StyledWebsiteImage id="demo-website" snippetAction={snippetAction}>
              <img src="img/examples/evil-cats-club-website.png" />
            </StyledWebsiteImage>
          </StyledDemoWebsite>
        </StyledDemoWebsiteColumn>
      </StyledBrowserSnippetsDemo>
      <StyledOutro>
        <StyledOutroTitle>Join the public beta 🚀</StyledOutroTitle>
        <StyledSubtitle style={{ textAlign: "center" }}>
          While we're in beta, everything is free!
          <br />
          Sign up to take advantage of:
        </StyledSubtitle>
        <div style={{ width: "100%" }}>
          <FeatureListWrapper>
            <FeatureListItem>
              <strong>Team access</strong>
              to easily share snippets
            </FeatureListItem>
            <FeatureListItem>
              <strong>Unlimited snippets</strong>
              for you and your team
            </FeatureListItem>
            <FeatureListItem>
              <strong>A cool new secret</strong>
              'cause we're so young 😏
            </FeatureListItem>
          </FeatureListWrapper>
        </div>
        <StyledButtonContainer>
          <StyledGetStartedButton
            inline={true}
            onClick={() => navigate("/register")}
          >
            Get started for free
          </StyledGetStartedButton>
        </StyledButtonContainer>
      </StyledOutro>

      <footer
        style={{
          padding: "2rem 1rem",
          textAlign: "center",
          fontSize: "0.8rem",
          backgroundColor: "#333",
          color: "white",
        }}
      >
        © Copyright Browser Snippets {currentYear}
      </footer>
    </>
  );
}

export default Homepage;
