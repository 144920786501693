const userInput = () => {
  // Sanitation and validity checks

  const isValidUsername = (u) => {
    // Usernames can contain characters a-z, 0-9, underscores and periods.
    // The username cannot start with a period nor end with a period.
    // It must also not have more than one period sequentially.
    // Min length is 3 chars. Max length is 30 chars.
    let username = u.replace(/\s/g, ""); // trim all spaces
    const usernameRegex = /^(?!.*\.\.)(?!.*\.$)[^\W][\w.]{2,29}$/gim;
    return usernameRegex.test(username);
  };

  const isValidEmail = (e) => {
    let email = e.replace(/\s/g, ""); // trim all spaces
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(email.toLowerCase());
  };

  const isValidPassword = (p) => {
    if (p.length > 0) {
      return p.length >= 8 && p.length <= 64;
    }
    // Requirements: 8-64 chars, both upper and lower chars, one number, one special
    // const passwordRegex = /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{8,64})/;
    // return passwordRegex.test(p);
    return false;
  };
  return {
    isValidUsername,
    isValidEmail,
    isValidPassword,
  };
};

export default userInput();
