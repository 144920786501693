import Colors from "./Colors";

export default {
  full: {
    thin: `0.125rem solid ${Colors.accent.primary}`,
    medium: `0.25rem solid ${Colors.accent.primary}`,
    thick: `0.5rem solid ${Colors.accent.primary}`,
    error: {
      thin: `0.125rem solid ${Colors.element.invalid}`,
      medium: `0.25rem solid ${Colors.element.invalid}`,
      thick: `0.5rem solid ${Colors.element.invalid}`,
    },
    warning: {
      thin: `0.125rem solid ${Colors.element.warning}`,
      medium: `0.25rem solid ${Colors.element.warning}`,
      thick: `0.5rem solid ${Colors.element.warning}`,
    },
  },
  radius: {
    standard: `0.3rem`,
    pill: `999rem`,
  },
};
