import React, { useState, useEffect } from "react";
import api from "../../helpers/apiHelper";

import SnippetsList from "./SnippetsList";
import AddSnippet from "./AddSnippet";

const Snippets = (props) => {
  const [userSnippetsLoaded, setLoadedUserSnippets] = useState(false);
  const [userSnippets, setUserSnippets] = useState({});

  // Get user or team snippets
  useEffect(() => {
    const getSnippets = async () => {
      let url = !!props.currentTeam.value
        ? `/snippets/team/${props.currentTeam.value}`
        : "/snippets";
      try {
        let snippets = await api.get(url);
        if (snippets.success) {
          setUserSnippets([...snippets.snippets]);
          setLoadedUserSnippets(true);
        }
      } catch (err) {
        console.error(err);
      }
    };

    getSnippets();
  }, [props]);

  let handleSnippetsListUpdate = (snippetsList, cb) => {
    setUserSnippets(snippetsList, cb);
  };

  return (
    userSnippetsLoaded && (
      <>
        <SnippetsList
          user={props.user}
          snippetsList={userSnippets}
          updateSnippetsList={handleSnippetsListUpdate}
        />
        <AddSnippet
          currentTeam={props.currentTeam}
          snippetsList={userSnippets}
          updateSnippetsList={handleSnippetsListUpdate}
        />
      </>
    )
  );
};

export default Snippets;
