import React from "react";
import styled from "styled-components";

import Spacing from "../style/tokens/Spacing";
import Borders from "../style/tokens/Borders";
import Typography from "../style/tokens/Typography";

const StyledTextInput = styled.input`
  display: block;
  width: 100%;
  margin-bottom: ${Spacing.medium};
  padding: ${Spacing.tight};

  border: ${Borders.full.thin};
  border-radius: ${Borders.radius.standard};
  box-sizing: border-box;

  ${(props) =>
    props.error &&
    `
    border: ${Borders.full.error.thin};
  `}
`;
const StyledTextareaInput = styled.textarea`
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: ${Spacing.medium};
  padding: ${Spacing.tight};

  border: ${Borders.full.thin};
  border-radius: ${Borders.radius.standard};
  box-sizing: border-box;

  ${(props) =>
    props.error &&
    `
    border: ${Borders.full.error.thin};
  `}
`;
const StyledTextInputLabel = styled.label`
  display: block;
  width: 100%;
  margin-bottom: ${Spacing.tight};
  font-size: ${Typography.body.small};
  ${(props) =>
    props.error &&
    `
    color: tomato;
  `}
`;

const TextInput = (props) => {
  let useTextarea = props.type && props.type === "textarea";
  let useCode = props.type && props.type === "code";

  let styledTextBox = (
    <StyledTextInput
      type={props.type || "text"}
      value={props.value}
      {...props}
    />
  );

  if (useTextarea) {
    styledTextBox = <StyledTextareaInput {...props}></StyledTextareaInput>;
  }

  if (useCode) {
    styledTextBox = (
      <pre>
        <StyledTextareaInput {...props}></StyledTextareaInput>
      </pre>
    );
  }
  return (
    <>
      {props.label && props.id && (
        <StyledTextInputLabel htmlFor={props.id}>
          {props.label}
        </StyledTextInputLabel>
      )}
      {styledTextBox}
    </>
  );
};

export default TextInput;
