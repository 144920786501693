import React, { useState, useEffect } from "react";

import { useAuth } from "../../hooks/useAuth";
import userInput from "../../helpers/userInputHelper";

import Form from "../ui/Form";
import TextInput from "../ui/TextInput";
import {
  StyledRegisterPage,
  StyledRegisterForm,
  StyledVideoContainer,
} from "./RegisterForm.styled";

import { useNavigate, useLocation } from "react-router-dom";

function RegisterForm() {
  const [formDisabled, setFormDisabled] = useState(false);
  const [renderForm, setRenderForm] = useState(false);
  const [formErrors, setFormErrors] = useState(false);

  let navigate = useNavigate();
  let location = useLocation();
  let authenticate = useAuth();

  let { from } = location.state || { from: { pathname: "/dashboard" } };

  useEffect(() => {
    if (authenticate.user != null) {
      authenticate.user
        ? navigate(from, { replace: true })
        : setRenderForm(true);
    }
  }, [authenticate.user, from]);

  let handleLogin = async (username, password) => {
    try {
      let response = await authenticate.login(username, password);

      if (response && !("error" in response)) {
        if (response) {
          navigate(from, { replace: true });
        }
      } else {
        setFormDisabled(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  let registerNewUser = async (username, email, password) => {
    try {
      let response = await authenticate.signup(username, email, password);

      if (response && response.success) {
        handleLogin(username, password);
      }

      if (response && response.error) {
        setFormDisabled(false);
        console.log("Registration error", response.error);
      }
    } catch (err) {
      setFormDisabled(false);
      console.log(err);
    }
  };

  let handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(false);
    setFormDisabled(true);

    let errors = [];

    let username = e.target.username.value;
    let email = e.target.email.value;
    let password = e.target.password.value;

    if (!userInput.isValidUsername(username)) {
      errors.push({ field: "username", message: "Try another username" });
    }

    if (email.length > 0 && !userInput.isValidEmail(email)) {
      errors.push({ field: "email", message: "Email is invalid" });
    }

    if (!userInput.isValidPassword(password)) {
      errors.push({
        field: "password",
        message: "Passwords must be 8+ characters",
      });
    }

    // Check if errors are empty
    if (errors.length === 0) {
      registerNewUser(username, email, password);
    } else {
      handleFormErrors(errors);
    }
  };

  let handleFormErrors = (obj) => {
    setFormDisabled(false);
    setFormErrors(obj);
  };

  return (
    renderForm && (
      <>
        <StyledRegisterPage>
          <StyledVideoContainer
            dangerouslySetInnerHTML={{
              __html: `
              <video
              muted autoplay loop playsinline
              >
              <source src="${process.env.PUBLIC_URL}/vid/browser-snippets-demo.mp4" type="video/mp4" />
              <source
                src="${process.env.PUBLIC_URL}/vid/browser-snippets-demo.webm"
                type="video/webm"
              />
              <source
                src="https://marcusmichaels.com/content/browser-snippets-demo.mp4"
                type="video/mp4"
              />
              </video>
            `,
            }}
          ></StyledVideoContainer>

          <StyledRegisterForm>
            {
              <>
                <h1 style={{ fontSize: "3rem", margin: "0 0 1rem" }}>
                  Register
                </h1>
                <Form
                  onSubmit={handleSubmit}
                  submitLabel="Sign up!"
                  disabled={formDisabled}
                  isLoading={formDisabled}
                  formErrors={formErrors}
                >
                  <TextInput
                    type="text"
                    name="username"
                    id="username"
                    autoComplete="username"
                    disabled={formDisabled}
                    label="Username"
                    error={
                      formErrors &&
                      formErrors.find((o) => o.field === "username")
                    }
                  />
                  <TextInput
                    type="text"
                    name="email"
                    id="email"
                    autoComplete="email"
                    disabled={formDisabled}
                    label="Email (optional for account recovery)"
                    error={
                      formErrors && formErrors.find((o) => o.field === "email")
                    }
                  />
                  <TextInput
                    type="password"
                    name="password"
                    id="password"
                    autoComplete="current-password"
                    disabled={formDisabled}
                    label="Password"
                    error={
                      formErrors &&
                      formErrors.find((o) => o.field === "password")
                    }
                  />
                </Form>
                <div
                  style={{
                    marginBottom: "1rem",
                    textAlign: "center",
                  }}
                >
                  <span style={{ fontFamily: "RedHatDisplay-Bold" }}>
                    Beta Edition
                  </span>
                  <br />
                  <br />
                  <small>
                    Browser Snippets is still being actively developed so things
                    are liable to change and maybe even break without notice.
                    Use at your own risk.
                  </small>
                </div>
              </>
            }
          </StyledRegisterForm>
        </StyledRegisterPage>
      </>
    )
  );
}

export default RegisterForm;
