import React, { useState } from "react";
import styled from "styled-components";

import Snippets from "../Snippets/Snippets";
import TeamSwitcher from "../Teams/TeamSwitcher";

const StyledHeaderSection = styled.div`
  padding: 0.5rem;
  margin: 0 0 1rem;
`;
const StyledTitle = styled.h1`
  margin-top: 0;
`;

const Dashboard = (props) => {
  const user = props.auth.user;
  const [currentTeam, setCurrentTeam] = useState({
    text: "My snippets",
    value: "",
  });

  const handleChangeTeam = (selection) => {
    setCurrentTeam(selection);
  };

  return (
    <div style={{ padding: "2rem", maxWidth: "50rem", margin: "auto" }}>
      <StyledHeaderSection>
        <StyledTitle>
          Hello {user.username}, here are your snippets:
        </StyledTitle>
        <TeamSwitcher currentTeam={currentTeam} changeTeam={handleChangeTeam} />
      </StyledHeaderSection>

      <Snippets user={user} currentTeam={currentTeam} />
      <small style={{ display: "block", padding: "1rem", textAlign: "center" }}>
        <a
          href="https://chrome.google.com/webstore/detail/browser-snippets/ofbdafhoclkjahpdhpmnfnppnepihmgi/"
          rel="noopener noreferrer"
          target="_blank"
        >
          Download Browser Snippets for Chrome
        </a>
      </small>
      {props.children}
    </div>
  );
};

export default Dashboard;
