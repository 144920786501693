import api from "../helpers/apiHelper";

// closure to securely handle in-memory short-lived JWT
const auth = () => {
  // private var
  let _token = null;

  const getToken = () => _token;

  const setToken = (token, callback) => {
    _token = token;

    // optional callback after token has been set
    if (callback) callback();
    return true;
  };

  const deleteTokens = async (options) => {
    try {
      if (options && options.refresh) {
        await refreshTokens();
      }

      let response = await api.post("/users/logout");

      if (response && response.success) {
        _token = null;
      }
    } catch (err) {
      console.log(err);
    }

    return true;
  };

  const hasRefreshToken = async () => {
    try {
      let data = await api.get("/auth/refresh_token", false);
      if (data && data.success) {
        return data.success;
      }
      return false;
    } catch (err) {
      console.log(err);
    }
  };

  const refreshTokens = async () => {
    try {
      let data = await api.post("/auth/refresh_token", {}, false);
      if (data && data.success) {
        setToken(data.accessToken);
      } else {
        // by now the refresh cookie has expired or doesn't exist so clear the in-memory token
        _token = null;
      }

      return data;
    } catch (err) {
      console.log(err);
    }
  };

  const requestTokens = async (username, password) => {
    // Used for logging a user in and requesting their tokens
    try {
      let data = await api.post(
        "/login",
        {
          body: JSON.stringify({
            username,
            password,
          }),
        },
        false
      );

      if (data && "error" in data) {
        deleteTokens();
      }

      return data;
    } catch (err) {
      console.log(err);
    }
  };

  const createUser = async (username, email, password) => {
    let body = {
      username,
      password,
    };

    if (email.length > 6) {
      body.email = email;
    }

    try {
      let data = await api.post(
        "/register",
        {
          body: JSON.stringify(body),
        },
        false
      );

      return data;
    } catch (err) {
      console.log("createUser:", err);
    }
  };

  const getUser = async () => {
    try {
      let user = await api.get("/users/identity");
      if (user && user.success) {
        return user;
      } else {
        deleteTokens();
        return false;
      }
    } catch (err) {
      console.log("getUser", err);
    }
  };

  const resetPassword = async (username) => {
    try {
      let response = await api.post(
        "/forgot",
        {
          body: JSON.stringify({
            username,
          }),
        },
        false
      );
      return response;
    } catch (err) {
      console.log("resetPassword", err);
    }
  };

  const confirmPasswordReset = async (password, resetToken) => {
    try {
      let response = await api.post(
        "/reset-password",
        {
          body: JSON.stringify({
            password,
            resetToken,
          }),
        },
        false
      );
      return response;
    } catch (err) {
      console.log("confirmPasswordReset", err);
    }
  };

  return {
    getToken,
    setToken,
    deleteTokens,
    hasRefreshToken,
    refreshTokens,
    requestTokens,
    createUser,
    getUser,
    resetPassword,
    confirmPasswordReset,
  };
};

export default auth();
