import styled from "styled-components";
import Spacing from "../style/tokens/Spacing";
import Borders from "../style/tokens/Borders";
import Colors from "../style/tokens/Colors";

export const StyledImageContainer = styled.div`
  width: 100%;
  max-width:90%;
  margin: auto;
  padding ${Spacing.loose};
  box-sizing: border-box;
`;

export const StyledImage = styled.img`
  max-width: 100%;
  box-sizing: border-box;
  border: ${Borders.full.thick};
  border-color: ${Colors.basic.blue};
  border-radius: 1rem;
  overflow: hidden;
`;

export const StyledLoginForm = styled.div`
  margin: auto;
  max-width: 20rem;
  padding ${Spacing.loose};
  /* padding-top: 5rem; */
`;

export const StyledLoginPage = styled.section`
  max-width: 1200px;
  margin: auto;
  @media (min-width: 1000px) {
    height: 70vh;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;

    padding-top: 2rem;

    ${StyledImageContainer} {
      width: 100%;
      position: relative;
    }

    ${StyledImage} {
      width: calc(100% - 4rem);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    ${StyledLoginForm} {
      min-width: 300px;
    }
  }
`;
