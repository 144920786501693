import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { ProvideAuth, useAuth } from "../../hooks/useAuth";

import Navigation from "../Navigation/Navigation";
import Dashboard from "../Dashboard/Dashboard";
import ManageTeams from "../Teams/ManageTeams";
import Homepage from "../Homepage/Homepage";
import LoginForm from "../LoginForm/LoginForm";
import RegisterForm from "../RegisterForm/RegisterForm";
import PasswordResetForm from "../PasswordResetForm/PasswordResetForm";
import PasswordChangeForm from "../PasswordChangeForm/PasswordChangeForm";
import Legals from "../Legals/Legals";

const Main = () => {
  return (
    <ProvideAuth>
      <Navigation />
      <Routes>
        {/* Logged in routes */}
        <Route
          path="/manage-teams"
          element={<PrivateRoute element={ManageTeams} />}
        />
        <Route
          path="/dashboard"
          element={<PrivateRoute element={Dashboard} />}
        />

        {/* Public routes */}
        <Route path="/login" element={<LoginForm />} />
        <Route path="/register" element={<RegisterForm />} />
        <Route path="/forgot" element={<PasswordResetForm />} />
        <Route path="/reset-password" element={<PasswordChangeForm />} />
        <Route path="/legals" element={<Legals />} />

        <Route path="/" element={<Homepage />} />
      </Routes>
    </ProvideAuth>
  );
};

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
const PrivateRoute = ({ element, children, ...rest }) => {
  let authenticate = useAuth();
  let Component = element;
  return (
    <>
      {authenticate.user ? (
        element ? (
          <Component auth={authenticate}>{children}</Component>
        ) : (
          children
        )
      ) : (
        <Navigate
          to={{
            // Pathname to redirect to if user is not authenticated
            pathname: "/login",
            state: { from: location },
          }}
        />
      )}
    </>
  );
};

export default Main;
